<!-- eslint-disable vue/no-unused-vars -->
<template>
  <div>
    <validation-observer ref="simpleRules">
      <button
        class="btn btn-gradient-primary mb-1"
        @click="Submit()"
      >
        {{ $t('saveData') }}
      </button>
      <b-row class="match-height">
        <b-col md="6">
          <div class="card">
            <div class="header-cardx p-2">
              <h3 class="mb-0">
                {{ $t('deliveryTypeInfo') }}
              </h3>
            </div>

            <div class="p-2">
              <b-row>
                <b-col md="12">
                  <b-form-group
                    label-for="h-product-name"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('firstName') }} :
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="Name"
                      rules="required"
                    >
                      <b-form-input
                        id="h-product-name"
                        v-model="Obj.name"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-service-fee"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('serviceFee') }} :
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="service Fee"
                      rules="required"
                    >
                      <b-form-input
                        id="h-service-fee"
                        v-model="Obj.service"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-printCoverSheet"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('printLabel') }} :
                    </template>

                    <b-form-checkbox
                      id="h-printCoverSheet"
                      v-model="Obj.print"
                      :value="1"
                      :unchecked-value="0"
                      class="mt-50"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-payShippingOrigin"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('shippingFeeOrigin') }} :
                    </template>
                    <b-form-checkbox
                      id="h-payShippingOrigin"
                      v-model="Obj.delivery_source"
                      name="check-buttons"
                      :value="1"
                      :unchecked-value="0"
                      switch
                      inline
                      class="mt-50"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-payDeliveryFee"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('payDestinationShipping') }} :
                    </template>
                    <b-form-checkbox
                      id="h-payDeliveryFee"
                      v-model="Obj.destination"
                      name="check-button"
                      :value="1"
                      :unchecked-value="0"
                      switch
                      inline
                      class="mt-50"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-addr"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('description') }} :
                    </template>
                    <b-form-textarea
                      id="h-addr"
                      v-model="Obj.footnote"
                      type="text"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-type"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('type') }} :
                    </template>

                    <div>
                      <b-form-radio
                        v-model="Obj.delivery_type"
                        name="some-radio9"
                        :value="1"
                        class="custom-control-primary mb-50 mt-50"
                      >
                        {{ $t('byWeightKg') }}
                      </b-form-radio>

                      <b-form-radio
                        v-model="Obj.delivery_type"
                        name="some-radio9"
                        :value="2"
                        class="custom-control-primary"
                      >
                        {{ $t('byVolumeCubicMeter') }}
                      </b-form-radio>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-col>

        <b-col md="6">
          <div
            class="card"
          >
            <div class="header-cardx p-2">
              <div class="d-flex align-items-center justify-content-between">
                <h3 class="mb-0">
                  {{ $t('pricing') }}
                </h3>

                <a
                  href="javascript:void(0)"
                  @click="addRow()"
                ><i class="fal fa-plus" /> {{ $t('addRow') }}</a>
              </div>
            </div>

            <div class="pl-2 pr-2">
              <b-table
                responsive="sm"
                :fields="fields"
                :items="Obj.rate"
                class="mb-2"
                show-empty
              >

                <template #empty>
                  <div class="text-center">
                    <img
                      src="@/assets/images/anan-img/empty/empty.png"
                      alt="empty"
                      width="180px"
                    >
                    <p>No Data</p>
                  </div>
                </template>

                <template #cell(minimum)="data">
                  <b-form-input
                    v-model="data.item.min"
                    type="number"
                    class="text-center"
                  />
                </template>

                <template #cell(price)="data">
                  <b-form-input
                    v-model="data.item.price"
                    type="number"
                    class="text-center"
                  />
                </template>

                <template #cell(action)="data">
                  <feather-icon
                    icon="TrashIcon"
                    class="text-danger cursor-pointer"
                    @click="removeRow(data.index)"
                  />
                </template>
              </b-table>
            </div>

          </div>
        </b-col>
      </b-row>
    </validation-observer>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  BTable,
  BFormRadio,
  BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

export default {
  components: {
    BRow,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BTable,
    BFormRadio,
    BFormCheckbox,
  },
  data() {
    return {
      required,

      items: [],
      Obj: {
        name: null,
        service: 0,
        print: 0,
        delivery_source: 0,
        destination: 0,
        footnote: null,
        delivery_type: 1,
        rate: [],
        agent_id: null,
      },
    }
  },
  computed: {
    fields() {
      return [
        {
          key: 'minimum', label: this.$t('minimum'), sortable: false, thStyle: { width: '25%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'price', label: this.$t('price'), sortable: false, thStyle: { width: '70%' }, thClass: 'text-center',
        },
        {
          key: 'action', label: this.$t('manage'), sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-right',
        },
      ]
    },
  },
  mounted() {

  },
  methods: {
    addRow() {
      console.log('addRow')
      const newRow = {
        min: 0,
        price: 0,
      }
      this.Obj.rate.push(newRow)
    },
    removeRow(index) {
      this.Obj.rate.splice(index, 1)
    },
    Submit() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          console.log(this.Obj)
          this.Obj.sequence = 0
          this.$http
            .post('/shipping_type/shipping_update', this.Obj)
            .then(() => {
              this.Success('เพิ่มประเภทจัดส่งสำเร็จ')
              this.$router.push({ name: 'admin-shipping-type' })
            })
            .catch(error => {
              this.SwalError(error.response.data.message)
            })
        }
      })
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        // title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: `<h3 style="color: #141414">${this.$t('key-298')}</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
